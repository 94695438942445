import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import store from '../store';

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            requireAuth: true,
            title: 'Manager',
            layout: 'pc',
            permission: 1
        },
        component: () => import('../views/Dashboard.vue')
    },
    {
        path: '/accusations',
        name: 'Accusation',
        meta: {
            requireAuth: true,
            title: 'Manager',
            layout: 'pc',
            permission: 1
        },
        component: () => import('../views/Accusations.vue')
    },
    {
        path: '/complaints',
        name: 'Complaint',
        meta: {
            requireAuth: true,
            title: 'Manager',
            layout: 'pc',
            permission: 1
        },
        component: () => import('../views/Complaints.vue')
    },
    {
        path: '/accusation-detail/:accusation_id',
        name: 'AccusationDetail',
        meta: {
            requireAuth: true,
            title: 'Manager',
            layout: 'pc',
            permission: 1
        },
        component: () => import('../views/AccusationDetail.vue')
    },
    {
        path: '/complaint-detail/:complaint_id',
        name: 'ComplaintDetail',
        meta: {
            requireAuth: true,
            title: 'Manager',
            layout: 'pc',
            permission: 1
        },
        component: () => import('../views/ComplaintDetail.vue')
    },
    {
        path: '/filed',
        name: 'Filed',
        meta: {
            requireAuth: true,
            title: 'Manager',
            layout: 'pc',
            permission: 1
        },
        component: () => import('../views/Filed.vue')
    },
    {
        path: '/filed-detail/:id',
        name: 'FiledDetail',
        meta: {
            requireAuth: true,
            title: 'Manager',
            layout: 'pc',
            permission: 1
        },
        component: () => import('../views/FiledDetail.vue')
    },
    {
        path: '/users',
        name: 'Users',
        meta: {
            requireAuth: true,
            title: 'Manager',
            layout: 'pc',
            permission: 2
        },
        component: () => import('../views/Users.vue')
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: {
            requireAuth: true,
            title: 'Manager',
            layout: 'pc',
            permission: 2
        },
        component: () => import('../views/Settings.vue')
    },
    {
        path: '/login',
        name: 'Login',
        layout: '',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/permission-denied',
        name: 'PermissionDenied',
        layout: '',
        component: () => import('../views/PermissionDenied.vue')
    },
    {
        path: '/:pathMatch(.*)',
        name: '404',
        layout: '',
        component: () => import('../views/404.vue')
    }
]

function generatePath(page) {
    let obj = routes[page];
    let result = {
        path: '/' + page,
        name: page,
        meta: { layout: "admin", showAs: obj.showAs || page },
        component: null
    };
    if (typeof obj === "function") {
        result.component = obj;
    } else {
        if (obj.layout) {
            obj.meta = { layout: obj.layout, showAs: obj.showAs || page };
        }
        result = { ...result, ...obj };
    }
    return result;
}

let pages = [];
for (let pc in routes) {
    pages.push(generatePath(pc));
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: pages
})

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {
        axios.post('/api/server/loginCheck').then((res) => {
            if (res.data.success) {
                store.dispatch({ type: 'login', data: { userName: res.data.data.account, userid: res.data.data.ID, permission: res.data.data.power, region: res.data.data.region } });
                if (to.meta.permission && to.meta.permission > res.data.data.power) {
                    next('permission-denied');
                } else {
                    next();
                }
            } else {
                next('login');
            }
        });
    } else {
        next();
    }
})

export default router
