import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Antd
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'


import Markdown from 'vue3-markdown-it';

// import layouts
import pcLayout from './layouts/pc.vue';

const app = createApp(App);

app.use(store).use(router).use(Antd).use(Markdown).mount('#app')

// layouts
app.component('pc-layout', pcLayout);