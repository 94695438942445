const default_value = {
    selectedKeys: [],
    openKeys: []
}

const page = {
    state() {
        return {
            selectedKeys: [],
            openKeys: []
        }
    },
    mutations: {
        setSelectedKeys(state, selectedKeys) {
            state.selectedKeys = selectedKeys;
        },
        setOpenKeys(state, openKeys) {
            state.openKeys = openKeys;
        }
    },
    actions: {
        setSelectedKeys(context, { selectedKeys }) {
            context.commit('setSelectedKeys', selectedKeys);
        },
        setOpenKeys(context, { openKeys }) {
            context.commit('setOpenKeys', openKeys);
        }
    }
}

export default page;