<template>
    <a-layout>
        <a-layout-header class="header">
            <div class="logo"><a href="/">随手拍后台管理系统</a></div>
            <!-- <a-menu theme="dark" mode="horizontal" v-model:selectedKeys="selectedKeys1" :style="{ lineHeight: '64px' }">
                <a-menu-item key="1">nav 1</a-menu-item>
                <a-menu-item key="2">nav 2</a-menu-item>
                <a-menu-item key="3">nav 3</a-menu-item>
            </a-menu> -->
            <div class="logout" v-if="$store.state.userInfo.userInfo.logined">
                <!-- <a :href="'/profile?ID=' + $store.state.userInfo.userInfo.userid"><a-avatar :src="'imgs/' + $store.state.userInfo.userInfo.avatar" /></a> -->
                {{greetings}}，{{$store.state.userInfo.userInfo.userName}}
                <a-button shape="round" @click="logout" size="small" ghost>登出</a-button>
            </div>
            <div class="logout" v-else>
                {{greetings}}，您尚未登录
                <a-button shape="round" @click="navigate('Login')" size="small" ghost>登录</a-button>
            </div>
        </a-layout-header>
        <a-layout>
            <a-layout-sider width="200" style="background: #fff">
                <div style="height: 100%;">
                    <a-menu mode="inline" v-model:selectedKeys="$store.state.page.selectedKeys" v-model:openKeys="$store.state.page.openKeys" :style="{ borderRight: 0 }">
                        <a-menu-item key="Home" @click="navigate('Home')"><home-outlined /> 首页</a-menu-item>
                        <a-sub-menu key="Manage">
                            <template #title>
                                <span>
                                    <bars-outlined />
                                    处理投诉/举报
                                </span>
                            </template>
                            <a-menu-item key="Complaints" @click="navigate('Complaint')">投诉</a-menu-item>
                            <a-menu-item key="Accusations" @click="navigate('Accusation')">举报</a-menu-item>
                            <!-- <a-menu-item key="3" @click="navigate('Measure')">测量</a-menu-item> -->
                            <!-- <a-menu-item key="2" @click="navigate('Log')">日志</a-menu-item> -->
                        </a-sub-menu>
                        <a-sub-menu key="Filed">
                            <template #title>
                                <span>
                                    <folder-open-outlined />
                                    归档
                                </span>
                            </template>
                            <a-menu-item key="Filed" @click="navigate('Filed')">查询归档数据</a-menu-item>
                            <!-- <a-menu-item key="6" @click="navigate('CalcData')">Calculated Data</a-menu-item> -->
                        </a-sub-menu>
                        <a-sub-menu key="Settings">
                            <template #title>
                                <span>
                                    <setting-outlined />
                                    设置
                                </span>
                            </template>
                            <a-menu-item key="Users" @click="navigate('Users')">管理员管理</a-menu-item>
                            <a-menu-item key="Settings" @click="navigate('Settings')">系统设置</a-menu-item>
                            <!-- <a-menu-item key="Log" @click="navigate('Log')">日志</a-menu-item> -->
                        </a-sub-menu>
                    </a-menu>
                </div>
            </a-layout-sider>
            <a-layout style="padding: 0 24px 24px">
                <!-- <a-breadcrumb style="margin: 16px 0">
                    <a-breadcrumb-item>Home</a-breadcrumb-item>
                    <a-breadcrumb-item>List</a-breadcrumb-item>
                    <a-breadcrumb-item>App</a-breadcrumb-item>
                </a-breadcrumb> -->
                <a-layout-content :style="{ background: '#fff', padding: '24px', margin: '16px 0' }" class="content-layout">
                    <slot></slot>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </a-layout>
</template>
<script>
import {
    BarsOutlined,
    FolderOpenOutlined,
    NotificationOutlined,
    SettingOutlined,
    HomeOutlined
} from "@ant-design/icons-vue";
import { defineComponent, ref, createVNode, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import { Modal, message } from "ant-design-vue";
export default defineComponent({
    components: {
        BarsOutlined,
        FolderOpenOutlined,
        NotificationOutlined,
        SettingOutlined,
        HomeOutlined
    },
    created() {
        this.greetingsByTime();
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const greetings = ref("");

        function navigate(name, key) {
            router.push({ name: name });
        }

        function logout() {
            axios
                .post("/api/server/userLogout")
                .then((res) => {
                    if (res.data.success) {
                        console.log("success");
                        store.dispatch({ type: "logout" });
                        navigate("Login");
                    }
                });
        }

        function greetingsByTime() {
            const now = new Date();
            const hour = now.getHours();
            if (hour < 6) {
                greetings.value = "凌晨好";
            } else if (hour < 9) {
                greetings.value = "早上好";
            } else if (hour < 12) {
                greetings.value = "上午好";
            } else if (hour < 14) {
                greetings.value = "中午好";
            } else if (hour < 17) {
                greetings.value = "下午好";
            } else if (hour < 19) {
                greetings.value = "傍晚好";
            } else if (hour < 22) {
                greetings.value = "晚上好";
            } else {
                greetings.value = "夜里好";
            }
        }

        onMounted(() => {
            store.dispatch({ type: 'setCategory' });
        })

        return {
            selectedKeys1: ref(["2"]),
            selectedKeys2: ref(["1"]),
            collapsed: ref(false),
            openKeys: ref(["file"]),
            navigate,
            logout,
            greetings,
            greetingsByTime
        };
    },
});
</script>
<style>
#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-background {
    background: #fff;
}

.content-layout {
    min-height: calc(100vh - 122px);
}

.header {
    display: inline;
    /* align-items: center; */
}

.logo {
    color: white;
    float: left;
}

.logo a {
    color: white;
    float: left;
    text-decoration: none;
}

.logout {
    color: white;
    float: right;
}

.announcement {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 100px;
    left: 100px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(124, 124, 124) 0px 0px 5px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(88, 88, 88);
    font-size: 25px;
}

.announcement:hover {
    cursor: pointer;
    border: rgb(68, 165, 255) solid 1px;
    box-shadow: rgb(68, 165, 255) 0px 0px 5px;
    color: rgb(68, 165, 255);
    white-space: pre-wrap;
    word-wrap: break-word;
}
</style>