import { createStore } from 'vuex'
import page from './modules/page'
import userInfo from './modules/userInfo'
import category from './modules/categories'

export default createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        page,
        userInfo,
        category
    }
})
