import axios from "axios";

const default_value = {
    selectedKeys: [],
    openKeys: []
}

const categories = {
    state() {
        return {
            category: {}
        }
    },
    mutations: {
        setCategory(state, cate) {
            state.category = cate;
        }
    },
    actions: {
        setCategory(context) {
            axios
                .post("/api/server/getCategoryMap")
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.success) {
                        res.data.data[0] = '';
                        context.commit('setCategory', res.data.data);
                    }
                });
        }
    }
}

export default categories;