const default_value = {
    userName: '',
    logined: false,
    userid: '',
    permission: 1,
    region: 0
}

const userInfo = {
    state() {
        return {
            userInfo: {
                userName: '',
                logined: false,
                userid: '',
                permission: 1,
                region: 0
            }
        }
    },
    mutations: {
        login(state, loginData) {
            state.userInfo = {
                ...default_value,
                ...loginData,
                logined: true
            };
        },
        logout(state) {
            state.userInfo = {
                ...default_value
            };
        }
    },
    actions: {
        login(context, { data }) {
            context.commit('login', data);
        },
        logout(context) {
            context.commit('logout');
        }
    }
}

export default userInfo;